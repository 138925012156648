export const Chevron = ({ down = true }) => {
  if (down) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ maxWidth: 20 }}
        version="1.1"
        viewBox="0 0 172 172"
      >
        <g
          fill="none"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeDasharray=""
          strokeDashoffset="0"
          fontFamily="none"
          fontWeight="none"
          fontSize="none"
          textAnchor="none"
        >
          <path d="M0,172v-172h172v172z" fill="none" />
          <g fill="#FFF">
            <path d="M17.2,68.8v-11.46667c0,-2.21307 1.27853,-4.2312 3.27947,-5.18293c2.00093,-0.95173 4.3688,-0.65933 6.0888,0.74533l59.43173,48.63013l59.43747,-48.63013c1.71427,-1.40467 4.08213,-1.69133 6.0888,-0.74533c2.00667,0.946 3.27373,2.96987 3.27373,5.18293v11.46667c0,1.72 -0.774,3.34827 -2.10413,4.4376l-63.06667,51.6c-2.1156,1.72573 -5.14853,1.72573 -7.26413,0l-63.06667,-51.6c-1.3244,-1.08933 -2.0984,-2.7176 -2.0984,-4.4376z" />
          </g>
        </g>
      </svg>
    );
  }
};
export const Download = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 172 172"
    style={{ maxWidth: 30 }}
  >
    <g
      fill="none"
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeMiterlimit="10"
      strokeDasharray=""
      strokeDashoffset="0"
      fontFamily="none"
      fontWeight="none"
      fontSize="none"
      textAnchor="none"
    >
      <path d="M0,172v-172h172v172z" fill="none" />
      <g fill="#FFF">
        <path d="M85.6775,3.3325c-0.14781,0.02688 -0.29562,0.06719 -0.43,0.1075c-1.59906,0.36281 -2.72781,1.80063 -2.6875,3.44v105.135l-21.6075,-21.6075c-0.83312,-0.86 -2.05594,-1.22281 -3.225,-0.9675c-1.29,0.22844 -2.32469,1.16906 -2.6875,2.41875c-0.36281,1.26313 0.01344,2.60688 0.9675,3.49375l27.52,27.52l2.4725,2.365l2.4725,-2.365l27.52,-27.52c1.37063,-1.37062 1.37063,-3.57437 0,-4.945c-1.37062,-1.37062 -3.57437,-1.37062 -4.945,0l-21.6075,21.6075v-105.135c0.04031,-0.99437 -0.36281,-1.94844 -1.075,-2.62031c-0.72562,-0.68531 -1.70656,-1.02125 -2.6875,-0.92719zM24.08,55.04v116.96h123.84v-116.96h-34.4c-1.23625,-0.01344 -2.39187,0.63156 -3.02344,1.70656c-0.61813,1.075 -0.61813,2.39187 0,3.46687c0.63156,1.075 1.78719,1.72 3.02344,1.70656h27.52v103.2h-110.08v-103.2h27.52c1.23625,0.01344 2.39188,-0.63156 3.02344,-1.70656c0.61813,-1.075 0.61813,-2.39187 0,-3.46687c-0.63156,-1.075 -1.78719,-1.72 -3.02344,-1.70656z" />
      </g>
    </g>
  </svg>
);

export const Logout = () => (
  <svg version="1.1" viewBox="0 0 172 172" style={{ maxWidth: 27 }}>
    <g
      fill="none"
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeMiterlimit="10"
      strokeDasharray=""
      strokeDashoffset="0"
      fontFamily="none"
      fontWeight="none"
      fontSize="none"
      textAnchor="none"
    >
      <path d="M0,172v-172h172v172z" fill="none" />
      <g fill="#FFF">
        <path d="M86,2.6875l-28.66667,40.3125h21.5v64.5h14.33333v-64.5h21.5zM43,11.70182c-25.628,14.93533 -43,42.65018 -43,74.29818c0,47.3 38.7,86 86,86c47.3,0 86,-38.7 86,-86c0,-31.648 -17.372,-59.36284 -43,-74.29818v17.16081c17.3505,13.10783 28.66667,33.8457 28.66667,57.13737c0,39.41667 -32.25,71.66667 -71.66667,71.66667c-39.41667,0 -71.66667,-32.25 -71.66667,-71.66667c0,-23.29883 11.31617,-44.02954 28.66667,-57.13737z" />
      </g>
    </g>
  </svg>
);

export const AddIcon = () => (
  <svg width="25px" height="23px" viewBox="0 0 25 23" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Desktop-HD-Copy-2"
        transform="translate(-24.000000, -786.000000)"
        stroke="#FC5542"
      >
        <g id="Group-4" transform="translate(25.000000, 786.982422)">
          <g id="Group-3">
            <polyline id="Path-2" points="5 0 0 0 0 21 5 21"></polyline>
            <polyline
              id="Path-2-Copy"
              transform="translate(20.500000, 10.500000) scale(-1, 1) translate(-20.500000, -10.500000) "
              points="23 0 18 0 18 21 23 21"
            ></polyline>
          </g>
          <line
            x1="5.5"
            y1="10.5175781"
            x2="17.5"
            y2="10.5175781"
            id="Line"
            strokeLinecap="square"
          ></line>
          <line
            x1="5.5"
            y1="10.5175781"
            x2="17.5"
            y2="10.5175781"
            id="Line"
            strokeLinecap="square"
            transform="translate(11.500000, 10.517578) rotate(90.000000) translate(-11.500000, -10.517578) "
          ></line>
        </g>
      </g>
    </g>
  </svg>
);

export const Left = ({ color = "#000", width = 15 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 172 172"
    style={{ width }}
  >
    <g
      fill="none"
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeMiterlimit="10"
      strokeDasharray=""
      strokeDashoffset="0"
      fontFamily="none"
      fontWeight="none"
      fontSize="none"
      textAnchor="none"
    >
      <path d="M0,172v-172h172v172z" fill="none" />
      <g fill={color}>
        <path d="M93.16667,21.5l-43,64.5l43,64.5h28.66667l-43,-64.5l43,-64.5z" />
      </g>
    </g>
  </svg>
);

export const Right = ({ color = "#000", width = 15 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 172 172"
    style={{ width }}
  >
    <g
      fill="none"
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeMiterlimit="10"
      strokeDasharray=""
      strokeDashoffset="0"
      fontFamily="none"
      fontWeight="none"
      fontSize="none"
      textAnchor="none"
    >
      <path d="M0,172v-172h172v172z" fill="none" />
      <g fill={color}>
        <path d="M57.33333,21.5l43,64.5l-43,64.5h28.66667l43,-64.5l-43,-64.5z" />
      </g>
    </g>
  </svg>
);

export const Logo = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 112.35 128.95"
    style={{ enableBackground: "new 0 0 112.35 128.95;" }}
  >
    <g>
      <g>
        <g>
          <g>
            <path
              className="st0"
              d="M56.09,10.59c-6.96,2.45-13.79,5.57-20.42,9.39c-6.62,3.82-12.74,8.18-18.34,12.99
					c-1.36,7.25-2.08,14.73-2.08,22.38s0.72,15.12,2.08,22.38c5.6,4.8,11.72,9.16,18.34,12.99c6.62,3.82,13.46,6.94,20.42,9.39
					c6.96-2.45,13.79-5.57,20.42-9.39c6.62-3.82,12.74-8.18,18.34-12.99c1.36-7.25,2.08-14.73,2.08-22.38
					c0-7.65-0.72-15.12-2.08-22.38c-5.6-4.8-11.72-9.16-18.34-12.99C69.88,16.16,63.05,13.04,56.09,10.59L56.09,10.59z"
            />
          </g>
        </g>
        <g>
          <g>
            <defs>
              <path
                id="SVGID_32_"
                d="M69.4,66.59c-0.63,0.75-1.33,1.44-2.07,2.07l-1.65-2.24c0.53-0.46,1.03-0.96,1.49-1.49L69.4,66.59z
						 M70.9,46.15l-2.26,1.62c0.36,0.6,0.68,1.23,0.96,1.88l2.64-0.88C71.86,47.85,71.41,46.98,70.9,46.15z M61.78,41.84
						c0.65,0.28,1.28,0.6,1.88,0.96l1.62-2.26c-0.83-0.52-1.7-0.96-2.61-1.34L61.78,41.84z M52.76,41.07
						c0.68-0.16,1.37-0.27,2.08-0.33l-0.02-2.79c-0.99,0.07-1.96,0.22-2.9,0.46L52.76,41.07z M48.51,42.8
						c0.6-0.36,1.23-0.68,1.88-0.96L49.5,39.2c-0.91,0.37-1.79,0.82-2.61,1.34L48.51,42.8z M57.33,40.74
						c0.71,0.06,1.41,0.17,2.08,0.33l0.84-2.66c-0.94-0.23-1.91-0.38-2.9-0.46L57.33,40.74z M45,45.76
						c0.46-0.53,0.96-1.03,1.49-1.49l-1.65-2.24c-0.75,0.63-1.44,1.33-2.07,2.07L45,45.76z M45,64.94l-2.24,1.65
						c0.63,0.75,1.33,1.44,2.07,2.07l1.65-2.24C45.96,65.97,45.46,65.47,45,64.94z M42.58,49.65c0.28-0.65,0.6-1.28,0.96-1.88
						l-2.26-1.62c-0.52,0.83-0.96,1.7-1.34,2.61L42.58,49.65z M67.17,45.76l2.24-1.65c-0.63-0.75-1.33-1.44-2.07-2.07l-1.65,2.24
						C66.21,44.73,66.71,45.22,67.17,45.76z M63.66,67.9c-0.6,0.36-1.23,0.68-1.88,0.96l0.88,2.64c0.91-0.37,1.79-0.82,2.61-1.34
						L63.66,67.9z M59.41,69.62c-0.68,0.16-1.37,0.27-2.08,0.33l0.02,2.79c0.99-0.07,1.96-0.22,2.9-0.46L59.41,69.62z M41.48,54.1
						c0.06-0.71,0.17-1.41,0.33-2.08l-2.66-0.84c-0.23,0.94-0.38,1.91-0.46,2.9L41.48,54.1z M69.6,61.04
						c-0.28,0.65-0.6,1.28-0.96,1.88l2.26,1.62c0.52-0.83,0.96-1.7,1.34-2.61L69.6,61.04z M70.36,52.02
						c0.16,0.68,0.27,1.37,0.33,2.08l2.79-0.02c-0.07-0.99-0.22-1.96-0.46-2.9L70.36,52.02z M70.69,56.59
						c-0.06,0.71-0.17,1.41-0.33,2.08l2.66,0.84c0.23-0.94,0.38-1.91,0.46-2.9L70.69,56.59z M43.54,62.92
						c-0.36-0.6-0.68-1.23-0.96-1.88l-2.64,0.88c0.37,0.91,0.82,1.79,1.34,2.61L43.54,62.92z M41.81,58.67
						c-0.16-0.68-0.27-1.37-0.33-2.08l-2.79,0.02c0.07,0.99,0.22,1.96,0.46,2.9L41.81,58.67z M54.84,69.95
						c-0.71-0.06-1.41-0.17-2.08-0.33l-0.84,2.66c0.94,0.23,1.91,0.38,2.9,0.46L54.84,69.95z M50.39,68.86
						c-0.65-0.28-1.28-0.6-1.88-0.96l-1.62,2.26c0.83,0.52,1.7,0.96,2.61,1.34L50.39,68.86z M48.25,54.05l-2.66-0.84
						c-0.41,1.37-0.64,2.8-0.64,4.28c0,0.06,0,0.12,0,0.18l2.81-0.89C47.83,55.84,47.99,54.93,48.25,54.05z M63.31,47.46l-1.7,2.3
						c0.54,0.64,1.01,1.34,1.41,2.09l2.67-0.89C65.07,49.68,64.27,48.51,63.31,47.46z M64.4,56.79l2.81,0.89c0-0.06,0-0.12,0-0.18
						c0-1.48-0.23-2.92-0.64-4.29l-2.66,0.84C64.18,54.93,64.34,55.84,64.4,56.79z M64.29,59.27
						C64.29,59.27,64.29,59.28,64.29,59.27c-0.07,0.5-0.35,0.93-0.74,1.24c-0.48,0.37-0.98,0.71-1.51,1.01l1.67,2.26
						c0.82-0.49,1.6-1.06,2.32-1.71l0,0c0.44-0.39,0.74-0.92,0.86-1.49l0.08-0.39c0-0.01,0-0.01-0.01-0.01L64.29,59.27z
						 M48.61,60.51c-0.39-0.3-0.66-0.74-0.74-1.23c0,0,0,0,0,0l-2.67,0.89c0,0.01,0,0.01-0.01,0.01l0.08,0.39
						c0.12,0.58,0.42,1.1,0.86,1.49h0c0.72,0.65,1.5,1.22,2.32,1.71l1.67-2.26C49.6,61.22,49.09,60.88,48.61,60.51z M54.9,63.04
						c-0.88-0.09-1.73-0.28-2.54-0.54l-1.69,2.36c1.34,0.52,2.75,0.85,4.2,0.97L54.9,63.04z M59.81,62.5
						c-0.81,0.26-1.66,0.45-2.54,0.54l0.02,2.79c1.45-0.12,2.86-0.45,4.2-0.97L59.81,62.5z M49.15,51.84
						c0.4-0.75,0.87-1.44,1.41-2.09l-1.7-2.3c-0.95,1.04-1.76,2.22-2.38,3.49L49.15,51.84z M52.28,48.08
						c0.79-0.64,1.67-1.18,2.61-1.6l-0.02-2.98c-1.55,0.55-2.96,1.33-4.21,2.31L52.28,48.08z M57.28,46.48
						c0.94,0.42,1.82,0.96,2.61,1.6l1.62-2.27c-1.25-0.98-2.66-1.76-4.21-2.31L57.28,46.48z"
              />
            </defs>
            <clipPath id="SVGID_2_"></clipPath>
            <g style={{ clipPath: "url(#SVGID_2_)" }}></g>
          </g>
          <path
            className="st2"
            d="M56.09,49.15c2.98,2.11,5.02,5.46,5.37,9.3c-1.63,0.75-3.45,1.17-5.37,1.17s-3.74-0.42-5.37-1.17
				C51.06,54.61,53.1,51.26,56.09,49.15L56.09,49.15z"
          />
        </g>
      </g>
      <g>
        <path
          className="st3"
          d="M31.48,128.72v-5.98h-6.72v5.98h-2.04v-13.33h2.04v5.56h6.72v-5.56h2.04v13.33H31.48z"
        />
        <path
          className="st3"
          d="M36.98,128.72v-13.33h8.13v1.79h-6.09v3.77h6.09v1.79h-6.09v4.19h6.09v1.79H36.98z"
        />
        <path
          className="st3"
          d="M48.03,128.72v-13.33h2.04v11.54h5.71v1.79H48.03z"
        />
        <path className="st3" d="M57.61,128.72v-13.33h2.04v13.33H57.61z" />
        <path
          className="st3"
          d="M69.36,128.95c-3.9,0-6.89-3.08-6.89-6.89c0-3.81,2.99-6.89,6.89-6.89c3.9,0,6.89,3.08,6.89,6.89
			C76.25,125.87,73.26,128.95,69.36,128.95z M69.36,116.97c-2.74,0-4.72,2.23-4.72,5.08c0,2.86,1.98,5.08,4.72,5.08
			s4.72-2.23,4.72-5.08C74.08,119.2,72.1,116.97,69.36,116.97z"
        />
        <path
          className="st3"
          d="M84.54,121.96l4.91,6.76h-2.61l-3.71-5.1l-3.62,5.1h-2.57l4.89-6.76l-4.74-6.57h2.65l3.54,4.95l3.52-4.95
			h2.55L84.54,121.96z"
        />
      </g>
    </g>
  </svg>
);
