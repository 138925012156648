import styles from "./Splash.module.css";
import { Link } from "react-router-dom";

const Splash = () => {
  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        <Link to="/" className={`btn-primary ${styles.link}`}>
          home
        </Link>
        <Link to="/about" className={`btn-primary ${styles.link}`}>
          About
        </Link>
        <Link to="/contact-us" className={`btn-primary ${styles.link}`}>
          Contact Us
        </Link>
        <Link to="/home" className={`btn-primary ${styles.button}`}>
          Login/Signup
        </Link>
      </div>
      <div className={styles.modal}>
        <img
          id="main-logo"
          className="logo"
          src="https://impactrepublic.github.io/heliox/images/black_heliox.png"
          with={170}
          height={170}
          style={{ position: "relative", left: -50, marginBottom: 30 }}
          alt="Helium-logo"
        />
        <h4 className={styles.title}>
          Track your projects and get insights with Heliox
        </h4>
        <p>
          Development life cycles are necessary for any system, whether it's
          software or hardware. They ensure that development is more efficient
          by planning out complex processes and preventing costly mistakes
          before they happen. We have made it simple for you.
        </p>
        <a
          href="https://impactrepublic.github.io/heliox/download/Heliox-x64.zip"
          className={`btn-primary ${styles.button}`}
        >
          Download App
        </a>
      </div>
      <footer className={styles.footer}>
        386 Park Avenue South, 18th Floor NY, NY 10016{" "}
        <strong>P. 212-441-0000</strong>
        <a href="mailto:hello@humanelevation.com">hello@humanelevation.com</a>
      </footer>
    </div>
  );
};

export default Splash;
