/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:bc31f1d5-7979-42f0-8fae-df364b7b54f3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_JL1F50Nqb",
    "aws_user_pools_web_client_id": "6d0p58pb9fbb70cdmuiih284f3",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://qg4r42d26ncd5abz7rkgd4le7u.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
