import React from "react";
import styles from "./Input.module.scss";

const Input = ({
  setForm,
  form,
  name,
  type,
  placeholder,
  label,
  max = 10,
  min = 1,
}) => {
  if (type === "area") {
    return (
      <div className={styles.form__group}>
        <label htmlFor={name}>{label}</label>
        <textarea
          className={styles.input}
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={(e) =>
            setForm({ ...form, [e.target.name]: e.target.value })
          }
        ></textarea>
      </div>
    );
  }

  return (
    <div className={styles.form__group}>
      <label htmlFor={name}>{label}</label>
      <input
        className={styles.input}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
      />
    </div>
  );
};

export default Input;
