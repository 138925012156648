import { useState, useEffect } from "react";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import awsconfig from "../../aws-exports";
import { listOperations } from "../../graphql/queries";
import { createOperation, deleteOperation } from "../../graphql/mutations";

import styles from "../../assets/styles/Home.module.scss";

import Navbar from "../../components/Nav";
import Header from "../../components/Header";
import Bl from "../../components/Bl";
import Rv from "../../components/Rv";
import Pr from "../../components/Pr";
import Co from "../../components/Co";
import Closed from "../../components/Closed";
import Form from "../../components/Form";
import Shopping from "../../components/Shopping";

Amplify.configure(awsconfig);

function Home() {
  const [blData, setBlData] = useState([]);
  const [rvData, setRvData] = useState([]);
  const [prData, setPrData] = useState([]);
  const [coData, setCoData] = useState([]);
  const [xData, setXData] = useState([]);

  const [records, setRecords] = useState([]);
  const [form, setForm] = useState({
    title: "",
    description: "",
    category: "backlog",
  });
  const [nav, setNav] = useState(false);
  const [bl, setBl] = useState(false);
  const [rv, setRv] = useState(false);
  const [pr, setPr] = useState(false);
  const [co, setCo] = useState(false);
  const [closed, setClosed] = useState(false);
  const [shopping, setShopping] = useState(false);
  const [background, setBackground] = useState({
    backgroundImage:
      "url('https://impactrepublic.github.io/heliox/images/home-bg.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  });

  const save = async (e) => {
    e.preventDefault();
    try {
      await API.graphql(
        graphqlOperation(createOperation, {
          input: form,
        })
      );
      fetchOperations();
      setNav(false);
      setShopping(false);
      setBl(false);
      setRv(false);
      setPr(false);
      setCo(false);
      setClosed(false);

      if (form.category === "backlog") setBl(true);
      if (form.category === "review") setRv(true);
      if (form.category === "progress") setPr(true);
      if (form.category === "completed") setCo(true);
      if (form.category === "closed") setClosed(true);
    } catch (e) {
      console.log(`There was an error saving the record: ${JSON.stringify(e)}`);
    }
  };

  const deleteItem = async (record) => {
    console.log(record);
    try {
      await API.graphql(
        graphqlOperation(deleteOperation, {
          input: { id: record, _version: 1 },
        })
      );
      setNav(false);
      setShopping(false);
      setBl(false);
      setRv(false);
      setPr(false);
      setCo(false);
      setClosed(false);
      fetchOperations();
    } catch (error) {
      if (error) {
        return console.log(
          `There was an error deleting the record: ${JSON.stringify(error)}`
        );
      }
    }
  };

  const updateItem = async (record, category) => {
    let idx;
    records.forEach((item, id) => {
      if (item.id === record.id) idx = id;
    });

    const newRecord = records[idx];
    newRecord.category = category;
    delete newRecord._deleted;
    delete newRecord._lastChangedAt;
    delete newRecord._version;
    delete newRecord.owner;
    delete newRecord.createdAt;
    delete newRecord.updatedAt;
    delete newRecord.id;

    console.log(newRecord);
    try {
      await API.graphql(
        graphqlOperation(createOperation, {
          input: newRecord,
        })
      );
      let updatedRecords = [...records];
      updatedRecords[idx] = newRecord;
      setRecords(updatedRecords);
    } catch (error) {
      if (error) {
        return console.log(
          `There was an error creating the record: ${JSON.stringify(error)}`
        );
      }
    }
  };

  const fetchOperations = async () => {
    setBlData([]);
    setRvData([]);
    setPrData([]);
    setCoData([]);
    setXData([]);
    try {
      const response = await API.graphql(
        graphqlOperation(listOperations, {
          category: "review",
        })
      );
      const result = response.data.listOperations.items;
      setRecords(result);
      const d = {
        bl: [],
        rv: [],
        pr: [],
        co: [],
        x: [],
      };
      result.map((record) => {
        switch (record.category) {
          case "backlog":
            if (record._deleted !== true) d.bl.push(record);
            break;

          case "review":
            if (record._deleted !== true) d.rv.push(record);
            break;

          case "progress":
            if (record._deleted !== true) d.pr.push(record);
            break;

          case "completed":
            if (record._deleted !== true) d.co.push(record);
            break;

          case "closed":
            if (record._deleted !== true) d.x.push(record);
            break;

          default:
            break;
        }
      });

      setBlData(d.bl);
      setRvData(d.rv);
      setPrData(d.pr);
      setCoData(d.co);
      setXData(d.x);
    } catch (error) {
      console.log(
        `There was an error fetching the records: ${JSON.stringify(error)}`
      );
    }
  };

  useEffect(() => {
    // fetch();
    fetchOperations();
    const mainLogo = document.getElementById("main-logo");
    mainLogo.remove();
  }, []);

  return (
    <div>
      <header className="App-header">{/* <AmplifySignOut /> */}</header>

      <Navbar
        setNav={setNav}
        setShopping={setShopping}
        setBl={setBl}
        setRv={setRv}
        setPr={setPr}
        setCo={setCo}
        setClosed={setClosed}
        setBackground={setBackground}
      />
      {bl && (
        <Bl
          records={blData}
          deleteItem={deleteItem}
          updateItem={updateItem}
          setShopping={setShopping}
          setBl={setBl}
          setRv={setRv}
          setPr={setPr}
          setCo={setCo}
          setBackground={setBackground}
          setClosed={setClosed}
        />
      )}
      {rv && (
        <Rv
          records={rvData}
          deleteItem={deleteItem}
          updateItem={updateItem}
          setShopping={setShopping}
          setBl={setBl}
          setRv={setRv}
          setPr={setPr}
          setCo={setCo}
          setBackground={setBackground}
          setClosed={setClosed}
        />
      )}
      {co && (
        <Co
          records={coData}
          deleteItem={deleteItem}
          updateItem={updateItem}
          setShopping={setShopping}
          setBl={setBl}
          setRv={setRv}
          setPr={setPr}
          setCo={setCo}
          setBackground={setBackground}
          setClosed={setClosed}
        />
      )}
      {shopping && (
        <Shopping
          records={records}
          deleteItem={deleteItem}
          updateItem={updateItem}
          setShopping={setShopping}
          setBackground={setBackground}
        />
      )}
      {pr && (
        <Pr
          records={prData}
          deleteItem={deleteItem}
          updateItem={updateItem}
          setShopping={setShopping}
          setBl={setBl}
          setRv={setRv}
          setPr={setPr}
          setCo={setCo}
          setBackground={setBackground}
          setClosed={setClosed}
        />
      )}
      {closed && (
        <Closed
          records={xData}
          deleteItem={deleteItem}
          updateItem={updateItem}
          setShopping={setShopping}
          setBl={setBl}
          setRv={setRv}
          setPr={setPr}
          setCo={setCo}
          setBackground={setBackground}
          setClosed={setClosed}
        />
      )}
      <div className={styles.home__section} style={background}>
        <div>
          <Header />
        </div>
        {nav && (
          <Form
            form={form}
            setForm={setForm}
            save={save}
            setNav={setNav}
            setShopping={setShopping}
          />
        )}
      </div>
    </div>
  );
}

export default withAuthenticator(Home);
