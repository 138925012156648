import React, { useState } from "react";
import styles from "./Subnav.module.scss";
import { Left, Right } from "../Icons";

const Details = ({ record, close, updateItem, deleteItem }) => {
  console.log(record);
  return (
    <div className={styles.records}>
      <a
        href="#0"
        onClick={(e) => {
          e.preventDefault();
          close(false);
        }}
      >
        X
      </a>
      <div className="record__details">
        <div className={styles.promotion__wrapper}>
          <a
            href="#0"
            className={styles.demoteBtn}
            onClick={(e) => {
              const id = record.id;
              e.preventDefault();
              updateItem(record, "backlog");
              deleteItem(id);
            }}
          >
            <Left color="#FFF" /> Demote
          </a>
          <a
            href="#0"
            className={styles.promotionBtn}
            onClick={(e) => {
              const id = record.id;
              e.preventDefault();
              updateItem(record, "progress");
              deleteItem(id);
            }}
          >
            Promote <Right color="#FFF" />
          </a>
        </div>

        <h5>Title</h5>
        <p>{record.title}</p>
        <hr />

        <h5>Description</h5>
        <p>{record.description}</p>
        <hr />
      </div>
    </div>
  );
};
const Rv = ({ records, deleteItem, setRv, setBackground, updateItem }) => {
  const [detail, setDetail] = useState(false);
  const [record, setRecord] = useState({});
  return (
    <div className={styles.subnav}>
      <a
        href="#0"
        className={styles.closeBtn}
        onClick={(e) => {
          e.preventDefault();
          setRv(false);
          setBackground({
            backgroundImage:
              "url('https://impactrepublic.github.io/heliox/images/home-bg.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          });
        }}
      >
        X
      </a>
      {detail && (
        <Details
          record={record}
          close={setDetail}
          updateItem={updateItem}
          deleteItem={deleteItem}
        />
      )}
      <div className={styles.title}>
        <span className={styles.bl}>RV</span>
        <h1>In Review</h1>
        <p>
          A pull request is an author's go-to for organizing and reviewing
          commits as they submit them. You'll finally be able to step away from
          jumping in your code edits. With this new In Review feature, you can
          put it all on the table by having conversations within a PR about how
          the changes will affect future programs or features that don't exist
          yet.
        </p>
      </div>

      <ul>
        {records.map((item, idx) => {
          const date = new Date(item.startDate);
          return (
            <li
              key={idx}
              onClick={(e) => {
                e.stopPropagation();
                setDetail(true);
                setRecord(item);
              }}
            >
              <h5>{item.title}</h5>
              <p>
                {item.description}
                <a
                  href="#0"
                  onClickCapture={(e) => {
                    e.preventDefault();
                    deleteItem(item.id);
                  }}
                >
                  X
                </a>
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Rv;
