import React, { useState } from "react";
import styles from "./Subnav.module.scss";
import { Left } from "../Icons";

const Details = ({ record, close, updateItem, deleteItem }) => {
  console.log(record);
  return (
    <div className={styles.records}>
      <a
        href="#0"
        onClick={(e) => {
          e.preventDefault();
          close(false);
        }}
      >
        X
      </a>
      <div className="record__details">
        <div className={styles.promotion__wrapper}>
          <a
            href="#0"
            className={styles.demoteBtn}
            onClick={(e) => {
              const id = record.id;
              e.preventDefault();
              updateItem(record, "completed");
              deleteItem(id);
            }}
          >
            <Left color="#FFF" /> Demote
          </a>
        </div>
        <h5>Title</h5>
        <p>{record.title}</p>
        <hr />

        <h5>Description</h5>
        <p>{record.description}</p>
        <hr />
      </div>
    </div>
  );
};
const Closed = ({
  records,
  deleteItem,
  setClosed,
  setBackground,
  updateItem,
}) => {
  const [detail, setDetail] = useState(false);
  const [record, setRecord] = useState({});
  return (
    <div className={styles.subnav}>
      <a
        href="#0"
        className={styles.closeBtn}
        onClick={(e) => {
          e.preventDefault();
          setClosed(false);
          setBackground({
            backgroundImage:
              "url('https://impactrepublic.github.io/heliox/images/home-bg.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          });
        }}
      >
        X
      </a>
      {detail && (
        <Details
          record={record}
          close={setDetail}
          updateItem={updateItem}
          deleteItem={deleteItem}
        />
      )}
      <div className={styles.title}>
        <span className={styles.bl}>X</span>
        <h1>Closed</h1>
        <p>
          A close task or project archives all the information associated with
          the project. You can reopen a closed task and work on it again by
          selecting it from the list in Task View in a desktop app like Project,
          but you cannot edit any of its data.
        </p>
      </div>

      <ul>
        {records.map((item, idx) => {
          const date = new Date(item.startDate);
          return (
            <li
              key={idx}
              onClick={(e) => {
                e.stopPropagation();
                setDetail(true);
                setRecord(item);
              }}
            >
              <h5>{item.title}</h5>
              <p>
                {item.description}
                <a
                  href="#0"
                  onClickCapture={(e) => {
                    e.preventDefault();
                    deleteItem(item.id);
                  }}
                >
                  X
                </a>
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Closed;
