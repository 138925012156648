import React from "react";
import styles from "./Shopping.module.scss";

const addIcon = () => (
  <svg width="25px" height="23px" viewBox="0 0 25 23" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Desktop-HD-Copy-2"
        transform="translate(-24.000000, -786.000000)"
        stroke="#FC5542"
      >
        <g id="Group-4" transform="translate(25.000000, 786.982422)">
          <g id="Group-3">
            <polyline id="Path-2" points="5 0 0 0 0 21 5 21"></polyline>
            <polyline
              id="Path-2-Copy"
              transform="translate(20.500000, 10.500000) scale(-1, 1) translate(-20.500000, -10.500000) "
              points="23 0 18 0 18 21 23 21"
            ></polyline>
          </g>
          <line
            x1="5.5"
            y1="10.5175781"
            x2="17.5"
            y2="10.5175781"
            id="Line"
            strokeLinecap="square"
          ></line>
          <line
            x1="5.5"
            y1="10.5175781"
            x2="17.5"
            y2="10.5175781"
            id="Line"
            strokeLinecap="square"
            transform="translate(11.500000, 10.517578) rotate(90.000000) translate(-11.500000, -10.517578) "
          ></line>
        </g>
      </g>
    </g>
  </svg>
);

const Shopping = ({ records, deleteItem, setShopping, setBackground }) => {
  return (
    <div className={styles.subnav}>
      <h1>{addIcon()} Studies</h1>
      <a
        href="#0"
        className={styles.closeBtn}
        onClick={(e) => {
          e.preventDefault();
          setShopping(false);
          setBackground({
            backgroundImage:
              "url('https://impactrepublic.github.io/heliox/images/home-bg.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          });
        }}
      >
        X
      </a>
      <p>
        When you need the information to help a physician, professional, or
        expert understand a problem or condition you are facing, we aid in you
        providing them a quality report.
      </p>
      <div className="container">
        <div className="row">
          <div className={`col-md-4 ${styles.thumb}`}>
            <img src="images/analytics.jpeg" alt="thumb" />
            <h5>Analytics </h5>
            <p>
              Contact us for information about our analytic services. Our team
              of world-renowned analysts can provide you with all the data that
              you need, and then some! Do not hesitate to contact us today.
            </p>
          </div>
          <div className={`col-md-4 ${styles.thumb}`}>
            <img src="images/consultation.jpg" alt="thumb" />
            <h5>Consultation</h5>
            <p>
              Project tracking can be difficult, to say the least. Even if you
              have an idea of where to look and what metrics matter most for
              your goals, it still takes so much work just to keep track of
              everything. That's why we want to provide you with a consultation
              service- one that will help establish goals and set up project
              data tracking from start-to-finish in only 30 minutes!
            </p>
          </div>
          <div className={`col-md-4 ${styles.thumb}`}>
            <img src="images/research.jpg" alt="thumb" />
            <h5>Research Reports</h5>
            <p>
              Need assistance with research? Obtain research data for with ease,
              whether it is gathering information on industry competitors or
              researching a certain topic to see if there are any gaps in the
              market that need to be filled. Get started by contacting us today
              and speaking to one of our friendly reps.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="container" style={{ textAlign: "center" }}>
            <a
              className={`btn btn-primary ${styles.contact__us__btb}`}
              href="mailto:hello@humanelevation.com"
            >
              Buy now
            </a>
            <p
              style={{
                maxWidth: "100%",
                marginLeft: 0,
                marginTop: "10px",
                marginRight: 0,
              }}
            >
              To order a report please, contact us
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shopping;
