import styles from "./About.module.css";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        <Link to="/" className={`btn-primary ${styles.link}`}>
          home
        </Link>
        <Link to="/about" className={`btn-primary ${styles.link}`}>
          About
        </Link>
        <Link to="/contact-us" className={`btn-primary ${styles.link}`}>
          Contact Us
        </Link>
        <Link to="/home" className={`btn-primary ${styles.button}`}>
          Login/Signup
        </Link>
      </div>
      <div> </div>
      <div className={styles.modal}>
        <img
          id="main-logo"
          className="logo"
          src="https://impactrepublic.github.io/heliox/images/black_heliox.png"
          with={150}
          height={150}
          style={{ position: "absolute", top: 0, left: 0 }}
          alt="Helium-logo"
        />
        <h4 className={styles.title}>About Us</h4>
        <p>
          Heliox is a software company that helps businesses manage their Agile
          teams. Heliox was founded in 2021 and has grown organically through
          referrals from customers. We are more focused on making great software
          than we are on growing quickly.
        </p>

        <p>
          The development of any system, whether it be software or hardware can
          result in a lot of data to manage the project. Software such as that
          which collects data, analyzes connections between things to rank them
          in order with storage capabilities have become integral in today's
          Business and without it, operations can become paralyzed with
          information management.
        </p>

        <p>
          The life cycle of both software and hardware systems are necessary
          because they play an essential role within a business functioning
          properly- we simplified it with Heliox.
        </p>

        <p>
          We pride ourselves on building the best project management system for
          developers because they deserve it!
        </p>
      </div>
      <footer className={styles.footer}>
        386 Park Avenue South, 18th Floor NY, NY 10016{" "}
        <strong>P. 212-441-0000</strong>
        <a href="mailto:hello@humanelevation.com">hello@humanelevation.com</a>
      </footer>
    </div>
  );
};

export default About;
