import React, { useState } from "react";
import Input from "../Input";
import styles from "./Form.module.scss";
import { AddIcon } from "../Icons";

const Form = ({ save, form, setForm, setNav, setShopping }) => {
  return (
    <form className={styles.form} action="#0" onSubmit={(e) => save(e)}>
      <h1>{AddIcon()} Add Record</h1>
      <p>Tap the state of the project and add it to the roadmap.</p>
      <div>
        <div>
          <a
            href="#0"
            className={`${styles.nav__item} ${styles.bl} ${
              form.category === "backlog" ? styles.bl__active : ""
            }`}
            onClick={() => setForm({ ...form, category: "backlog" })}
            alt="Backlog"
          >
            BL
          </a>
          <a
            href="#0"
            className={`${styles.nav__item} ${styles.rv} ${
              form.category === "review" ? styles.rv__active : ""
            }`}
            onClick={() => setForm({ ...form, category: "review" })}
            alt="Review"
          >
            RV
          </a>
          <a
            href="#0"
            className={`${styles.nav__item} ${styles.pr} ${
              form.category === "progress" ? styles.pr__active : ""
            }`}
            onClick={() => setForm({ ...form, category: "progress" })}
            alt="Progress"
          >
            PR
          </a>
          <a
            href="#0"
            className={`${styles.nav__item} ${styles.co} ${
              form.category === "completed" ? styles.co__active : ""
            }`}
            onClick={() => setForm({ ...form, category: "completed" })}
            alt="Completed"
          >
            CO
          </a>
          <a
            href="#0"
            className={`${styles.nav__item} ${styles.x} ${
              form.category === "closed" ? styles.x__active : ""
            }`}
            onClick={() => setForm({ ...form, category: "closed" })}
            alt="Closed"
          >
            X
          </a>
        </div>
        <Input
          setForm={setForm}
          form={form}
          type="text"
          placeholder="Title for the operation"
          name="title"
          label="Operation"
        />
        <Input
          setForm={setForm}
          form={form}
          type="area"
          placeholder="Please type the description for this operation"
          name="description"
          label="Description"
        />

        <button className="btn btn-primary" type="submit">
          Save
        </button>
        <button
          className="btn btn-secondary"
          type="button"
          onClick={() => {
            setNav(false);
            setShopping(false);
          }}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default Form;
