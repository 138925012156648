import styles from "./ContactUs.module.css";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        <Link to="/" className={`btn-primary ${styles.link}`}>
          home
        </Link>
        <Link to="/about" className={`btn-primary ${styles.link}`}>
          About
        </Link>
        <Link to="/contact-us" className={`btn-primary ${styles.link}`}>
          Contact Us
        </Link>
        <Link to="/home" className={`btn-primary ${styles.button}`}>
          Login/Signup
        </Link>
      </div>
      <div> </div>
      <div className={styles.modal}>
        <img
          id="main-logo"
          className="logo"
          src="https://impactrepublic.github.io/heliox/images/black_heliox.png"
          with={150}
          height={150}
          style={{ position: "absolute", top: 0, left: 0 }}
          alt="Helium-logo"
        />
        <h4 className={styles.title}>Contact Us</h4>
        <p>
          Let us know if you're interested in our product! We'll be glad to
          answer any of your questions.
        </p>
        <a
          href="mailto:hello@humanelevation.com"
          className={`btn-primary ${styles.button}`}
        >
          Contact Us
        </a>
      </div>
      <footer className={styles.footer}>
        386 Park Avenue South, 18th Floor NY, NY 10016{" "}
        <strong>P. 212-441-0000</strong>
        <a href="mailto:hello@humanelevation.com">hello@humanelevation.com</a>
      </footer>
    </div>
  );
};

export default ContactUs;
