import React from "react";
import { Chevron } from "../Icons";
import styles from "./Header.module.css";

const Header = () => (
  <>
    <img
      className="logo"
      src="https://impactrepublic.github.io/heliox/images/entities_heliox.png"
      alt="Helium-logo"
    />
    <h4 className={styles.title} style={{ color: "#FFF", margin: "auto" }}>
      Track your projects and get insights with Heliox
    </h4>
    <p
      style={{ color: "#FFF", margin: "auto", marginTop: 20, marginBottom: 20 }}
    >
      Development life cycles are necessary for any system, whether it's
      software or hardware. They ensure that development is more efficient by
      planning out complex processes and preventing costly mistakes before they
      happen. We have made it simple for you.
    </p>
    <a
      className={styles.downloadBtn}
      href="https://impactrepublic.github.io/heliox/download/Heliox-x64.zip"
      download
      alt="Download"
    >
      <span>Download App</span> <Chevron />
    </a>
  </>
);

export default Header;
