import React from "react";
import styles from "./Navbar.module.scss";
import { Logout, Download } from "../Icons";
import { Auth } from "aws-amplify";

const Navbar = ({
  setNav,
  setShopping,
  setBl,
  setRv,
  setPr,
  setCo,
  setClosed,
  setBackground,
}) => {
  const signOut = async (e) => {
    e.preventDefault();
    try {
      await Auth.signOut();
      document.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
  return (
    <div className={styles.nav}>
      <a href="/" title="Heliox">
        <img
          src="https://impactrepublic.github.io/heliox/images/heliox_icon.png"
          alt="add"
        />
      </a>
      <div className="links-wrapper">
        <a
          className={styles.item}
          href="#0"
          onClick={(e) => {
            e.preventDefault();
            setNav(true);
            setShopping(false);
            setBl(false);
            setRv(false);
            setPr(false);
            setCo(false);
            setClosed(false);
          }}
          title="Add record"
        >
          <img
            src="https://impactrepublic.github.io/helium/images/add-icon.svg"
            alt="add"
          />
        </a>

        <a className={styles.item} href="#0" title="Buy report">
          <img
            src="https://impactrepublic.github.io/helium/images/shopping-icon.svg"
            alt="reports"
            onClick={(e) => {
              e.preventDefault();
              setNav(false);
              setBl(false);
              setShopping(true);
            }}
          />
        </a>

        <a
          href="#0"
          onClick={(e) => {
            e.preventDefault();
            setNav(false);
            setShopping(false);
            setBl(true);
            setRv(false);
            setPr(false);
            setCo(false);
            setClosed(false);
            setBackground({
              backgroundImage:
                "url('https://impactrepublic.github.io/heliox/images/bl-background.jpeg')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            });
          }}
          className={`${styles.nav__item} ${styles.bl}`}
          alt="Backlog"
        >
          BL
        </a>
        <a
          href="#0"
          onClick={(e) => {
            e.preventDefault();
            setNav(false);
            setShopping(false);
            setBl(false);
            setRv(true);
            setPr(false);
            setCo(false);
            setClosed(false);
            setBackground({
              backgroundImage:
                "url('https://impactrepublic.github.io/heliox/images/rv-background.jpg')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            });
          }}
          className={`${styles.nav__item} ${styles.rv}`}
          alt="Under Review"
        >
          RV
        </a>
        <a
          href="#0"
          onClick={(e) => {
            e.preventDefault();
            setNav(false);
            setShopping(false);
            setBl(false);
            setRv(false);
            setPr(true);
            setCo(false);
            setClosed(false);
            setBackground({
              backgroundImage:
                "url('https://impactrepublic.github.io/heliox/images/pr-background.jpg')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            });
          }}
          className={`${styles.nav__item} ${styles.pr}`}
          alt="In Progress"
        >
          PR
        </a>
        <a
          href="#0"
          className={`${styles.nav__item} ${styles.co}`}
          alt="Completed"
          onClick={(e) => {
            e.preventDefault();
            setNav(false);
            setShopping(false);
            setBl(false);
            setRv(false);
            setPr(false);
            setCo(true);
            setClosed(false);
            setBackground({
              backgroundImage:
                "url('https://impactrepublic.github.io/heliox/images/co-background.jpeg')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            });
          }}
        >
          CO
        </a>
        <a
          href="#0"
          className={`${styles.nav__item} ${styles.x}`}
          style={{
            marginBottom: "20px",
          }}
          onClick={(e) => {
            e.preventDefault();
            setNav(false);
            setShopping(false);
            setBl(false);
            setRv(false);
            setPr(false);
            setCo(false);
            setClosed(true);
            setBackground({
              backgroundImage:
                "url('https://impactrepublic.github.io/heliox/images/closed-background.jpeg')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            });
          }}
          alt="Closed"
        >
          X
        </a>
        <a
          className={styles.item}
          href="https://impactrepublic.github.io/heliox/download/Heliox-x64.zip"
          download
          alt="Download"
        >
          <Download />
        </a>
        <a
          className={styles.item}
          href="#0"
          onClick={(e) => {
            e.preventDefault();
            signOut(e);
          }}
        >
          <Logout />
        </a>
      </div>
    </div>
  );
};

export default Navbar;
